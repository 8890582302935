@font-face {
  font-family: 'DS-Digital';
  src: url('../public/fonts/DS-DIGIB.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GmarketSans';
  src: url('../public/fonts/GmarketSansTTFMedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GmarketSans';
  src: url('../public/fonts/GmarketSansTTFBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GmarketSans';
  src: url('../public/fonts/GmarketSansTTFLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: 'GmarketSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  @apply w-full h-full;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-digit {
  font-family: 'DS-Digital', sans-serif;
}

@layer components {
  .txtInput {
    display: block;
    background-color: #fff;
    font-size:25px;
    font-weight: 500;
    text-align: center;
    padding: 20px;
    height: 80px;
    border: solid 2px #ededed;
    border-radius: 10px;
  }
  .txtInput::placeholder {
    color: #c6c6c6;
  }
  .mo-txtInput {
    @apply text-m6;
    display: block;
    background-color: #fff;
    font-weight: 500;
    text-align: center;
    padding: 22.5px;
    border: solid 1.5px #ededed;
    border-radius: 5px;
  }
  .mo-txtInput::placeholder {
    color: #c6c6c6;
  }
  
  .mo-btn-stock-buy {
    @apply bg-coral;
    color: #ffffff;
    border-color: #d50000;
  }
  .mo-btn-stock-sell {
    @apply bg-dodgerBlue;
    color: #ffffff;
    border-color: #104bdd;
  }
  .mo-btn-stock-buy,
  .mo-btn-stock-sell {
    @apply border text-m4 font-medium ;
    border-radius: 5px;
    padding: 34px 34px 28px;
  }
  .mo-btn-stock-buy > span,
  .mo-btn-stock-sell > span {
    @apply text-m6 ;
  }
  .mo-btn-stock-buy:disabled,
  .mo-btn-stock-sell:disabled {
    @apply bg-browngrey;
    border-color: #727272;
    color: #747474;
  }

  .btn {
    display: block;
    background-color: var(--theme-button-color);
    color: var(--theme-button-font-color);
    text-align: center;
    font-size: 28px;
    line-height: 47px;
    height: 80px;
    padding: 20px;
    border-radius: 10px;
  }
  .mo-btn:disabled,
  .btn:disabled {
    background-color: #a2a2a2;
    color: #707070;
  }
  .mo-btn {
    display: block;
    background-color: var(--theme-button-color);
    color: var(--theme-button-font-color);
    text-align: center;
    font-size: 17px;
    line-height: 15px;
    height: 42px;
    padding: 14px 10px 10px;
    border-radius: 5px;
  }

  .stepItem {
    position: relative;
    width: 146px;
    height: 40px;
    border-radius: 30px;
    @apply border opacity-40 border-white flex justify-center items-center;
  }
  .stepItem > span {
    @apply text-white;
  }

  .stepItem.active {
    opacity: 1;
    @apply border-pastelBlue bg-pastelBlue;
    box-shadow: 0 0 10px 0 rgba(181, 179, 248, 0.81);
  }
  .stepItem.active > span {
    @apply text-darkBlueGray;
  }

  .stepItem .innerArrow {
    position: absolute;
    width: 10px;
    height: 0;
    border:solid 1.5px #fff;
    right:0;
  }
  
  .stepItem.active .innerArrow {
    border:solid 1.5px #172547;
  }
  .stepItem .outterArrow {
    position:absolute;
    width: 16px;
    border:solid 1.5px #fff;
    right: -16px;
  }
  .stepItem .outterArrow::before,
  .stepItem .outterArrow::after {
    content: "";
    position:absolute;
    right: 0px;
    display:block;
    width: 8.5px;
    border: solid 1.5px #fff;
  }
  .stepItem .outterArrow::before {
    transform-origin: right center;
    transform: translateY(-1px) rotateZ(45deg);
  }
  .stepItem .outterArrow::after {
    transform-origin: right center;
    transform: translateY(-1px) rotateZ(-45deg);
  }

  .pink-btn-mo {
    @apply bg-veryLightPink text-darkBlueGray text-m6;
    border-radius: 5px;
    padding: 18px 16px 16px;
    height:50px;
  }
  .pink-btn-mo:disabled {
    @apply bg-browngrey text-brownishGrey;
  }
  .blue-btn-mo {
    @apply bg-pastelBlue text-darkBlueGray text-m6;
    border-radius: 5px;
    padding: 18px 16px 16px;
    height:50px;
  }
  .blue-btn-mo:disabled {
    @apply bg-browngrey text-brownishGrey;
  }
  .red-btn-mo {
    @apply bg-coral text-white text-m6;
    border-radius: 5px;
    padding: 18px 16px 16px;
    height:50px;
  }
  .red-btn-mo:disabled {
    @apply bg-browngrey text-brownishGrey;
  }

  .dodgerblue-btn-mo {
    @apply bg-dodgerBlue text-white text-m6;
    border-radius: 5px;
    padding: 18px 16px 16px;
    height:50px;
  }

  .dodgerblue-btn-mo:disabled {
    @apply bg-browngrey text-brownishGrey;
  }
  .ic-minus {
    position: relative;
    display:block;
    width: 24px;
    height:24px;
  }
  .ic-minus::before {
    position:absolute;
    content: "";
    width: 22.5px;
    height: 2px;
    display:block;
    background-color: white;
    left: 50%;
    top: 50%;
    transform:translate(-50%, -50%);
  }
  .ic-plus {
    position: relative;
    display:block;
    width: 24px;
    height:24px;
  }
  .ic-plus::before {
    position:absolute;
    content: "";
    width: 22.5px;
    height: 2px;
    display:block;
    background-color: white;
    left: 50%;
    top: 50%;
    transform:translate(-50%, -50%);
  }
  .ic-plus::after {
    position:absolute;
    content: "";
    width: 22.5px;
    height: 2px;
    display:block;
    background-color: white;
    left: 50%;
    top: 50%;
    transform:translate(-50%, -50%) rotateZ(90deg);
  }
  *:disabled > .ic-minus::before,
  *:disabled > .ic-plus::after,
  *:disabled > .ic-plus::before {
    background-color: #707070;
  }
}